<!--
 * @Description: 点击图片验证码
-->
<template>
  <div class='main'>
    <div class="title">
      <span>开始验证</span>
      <span style="float:right;margin-right:10px;cursor: pointer;"
            @click="handleClickClose">x</span>
    </div>
    <div class="content">
      <!-- 图片验证 /cqparkApi/login/LoginController/loginValidateCode  src="../assets/login/aa.png"-->
      <div class="content_div">
        <img ref="vcImg"
             class="content_img"
             :src="verifyCode"
             @click="getVerifyCode()">
      </div>
    </div>
    <div class="tip">
      <span style="margin-right: 10px;">请依次输入:</span>
      <input type="text"
             v-model="test"
             ref="inputFocus"
             placeholder="验证码（不区分大小写）"
             autocomplete="off"
             class="tip_input"
             lay-verify="required"
             lay-reqtext="请输入验证码!">
      <div @click="confirm"
           class="but">确认</div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      uuid: '',
      verifyCode: ''
    };
  },
  //监听属性 类似于data概念
  computed: {
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.$nextTick(() => {
      this.$refs.inputFocus.focus()
    })
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getCaptchaImage()
    this.$keyBoard(this, "confirm", 13); //验证码回车确认事件
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 点击关闭
    handleClickClose () {
      this.$emit('close', false)
    },
    //  确认按钮点击
    confirm () {
      // let ver = this.poitisTrue.includes(false) ? false : true
      if (this.test !== '') {
        this.$emit('confirm', this.test)
      } else {
        console.log('请输入验证码');
      }
    },
    getCaptchaImage () {
      this.$login.captchaImage().then(res => {
        this.verifyCode = 'data:image/png;base64,' + res.image
        this.uuid = res.uuid
        this.$emit('uuid', this.uuid)
      })
    },
    //点击刷新验证码
    getVerifyCode () {
      this.getCaptchaImage()
    }
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.main {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  width: 400px;
  border-radius: 10px;
  overflow: hidden;
  .title {
    width: 100%;
    height: 30px;
    text-align: center;
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    //background: #004077;
    background: #ffffff;
  }
  .content {
    position: relative;
    width: 100%;
    height: 200px;
    background: url('../assets/login/loginBackGround.png');
    background-size: 100% 100%;
    color: #0e71db;
    font-weight: 800;
    font-size: 32px;
    overflow: hidden;
    .content_div {
      width: 390px;
      height: 190px;
      //border: 5px solid #0a2041;
      .content_img {
        width: 390px;
        height: 190px;
      }
    }
  }
  .tip {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    font-weight: 600;
    line-height: 50px;
    color: #cf4397;
    background: #ffffff;
    .tip_input {
      width: 150px;
      height: 25px;
      border: 0px;
      outline: none;
    }
    .but {
      display: flex;
      justify-content: center;
      align-items: cneter;
      width: 80px;
      height: 30px;
      margin-left: 30px;
      line-height: 30px;
      background: #0e71db;
      border-radius: 10px;
      color: #ffffff;
      border: 1px solid rgb(77, 156, 221);
      &:active {
        background: #3a8ce4;
      }
    }
    .but:hover {
      cursor: pointer;
    }
  }
}
</style>