<!--
 * @Description: 登录页面
 * @Author: 周成
 * @Date: 2020-10-29 10:13:00
 * @LastEditTime: 2024-01-31 15:23:24
 * @LastEditors: houjinduo
-->
<template>
  <div class="login-content">
    <el-row class="login-block">
      <el-row class="title">
        <el-col :span="4">
          <svg width="44px"
               height="56px"
               viewBox="0 -2 44 41"
               version="1.1"
               xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink">
            <title></title>
            <defs>
              <linearGradient x1="50%"
                              y1="0%"
                              x2="50%"
                              y2="100%"
                              id="linearGradient-1">
                <stop stop-color="#FFFFFF"
                      offset="0%"></stop>
                <stop stop-color="#BDE5FF"
                      offset="100%"></stop>
              </linearGradient>
            </defs>
            <g id="路内停车管理"
               stroke="none"
               stroke-width="1"
               fill="none"
               fill-rule="evenodd">
              <g id="登陆"
                 transform="translate(-1309.000000, -304.000000)">
                <g id="logoin备份"
                   transform="translate(1309.000000, 296.000000)">
                  <g id="logo"
                     transform="translate(0.000000, 8.000000)">
                    <path d="M18,40.8230011 L12.4615385,38.6705011 L13.6153846,17.4716375 C13.6153846,17.4716375 2.07692308,15.6080011 4.01456646e-13,8.38641023 L6.92307692,7.45459205 C6.92307692,7.45459205 7.84615385,12.113683 13.8461538,13.9773193 L14.3076923,0.931864773 L19.841,0.075 L18.0047077,40.8229545 L18.4616308,41 L18,40.8230011 Z M20.3077846,1.42108547e-14 L20.7692308,0.284251136 L24,2.32959205 L22.1538462,39.6023193 L18.932,40.82 L20.7554769,0.284204545 L20.3077846,1.42108547e-14 Z"
                          id="形状结合"
                          fill="url(#linearGradient-1)"></path>
                    <path d="M24.8656813,3 L27.1977829,4.38461538 L28.1306235,4.93846154 L30.9291455,6.69230769 L30.2155224,18.7292308 C31.9925838,19.0430769 44.4226854,21.4292308 43.9889145,27 C43.5440213,32.7230769 31.6414162,35.9266454 30.5436619,36.2101683 L30.471905,36.2284769 C30.4658162,36.2300046 30.4627252,36.2307692 30.4627252,36.2307692 L30.6959353,32.7692308 C30.6959353,32.7692308 37.2258198,30.4615385 37.2258198,27.2307692 C37.2258198,24.1292308 32.0951963,22.3061538 30.0102974,22.1538462 L30.0102974,22.3753846 L29.5438771,36.6830769 L26.7826688,37.6615385 L26.768,37.666 L28.088739,4.96153846 L27.1792194,4.40769231 L25.817272,38.0030769 L26.061,37.918 L25.8498282,37.9938462 L23,39 L24.8656813,3 Z"
                          id="形状结合"
                          fill="#FFFFFF"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </el-col>
        <el-col :span="20">渝北智慧停车</el-col>
      </el-row>
      <el-form class="login-form-horizontal"
               :model="loginList"
               ref="loginList"
               :rules="rules">
        <el-form-item prop="userCode">
          <el-input class="input"
                    style="width:360px"
                    v-model="loginList.userCode"
                    :readonly='codeShow'
                    placeholder="请输入账号" />
        </el-form-item>
        <el-form-item prop="passWord">
          <el-input class="input"
                    style="margin-top:30px;width:360px"
                    :readonly='codeShow'
                    type="password"
                    show-password
                    v-model="loginList.passWord"
                    placeholder="请输入密码" />
        </el-form-item>
        <el-button id="login-btn"
                   @click="loginBtn()">登录</el-button>
      </el-form>
    </el-row>
    <!-- 渝ICP备2021012963号 -->
    <el-link class="linkClass"
             href="http://beian.miit.gov.cn"
             target="_blank">苏ICP备2023051531号</el-link>
    <!-- 验证码 -->
    <div class="codeBox"
         v-if="showClickWordVerificationCode">
      <clickWordVerificationCode @uuid="getUUid"
                                 @confirm="clickCodeConfirm"
                                 @close='handleClickCloseCode'></clickWordVerificationCode>
    </div>
  </div>
</template>
<script>
//import clickWordVerificationCode from "@/components/clickWordVerificationCode.vue";  // 汉字验证码
import clickWordVerificationCode from "../../components/imgVerificationCode.vue";  // 数字验证码
import md5 from 'js-md5'

export default {
  components: {
    clickWordVerificationCode
  },
  data () {
    return {
      uuid: '',
      codeShow: false,
      // 登录绑定
      loginList: {
        userCode: "",
        passWord: "",
        captchaCode: ""
      },
      rules: {
        userCode: [{ required: true, message: "请输入账号", trigger: "blur" }],
        passWord: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      showClickWordVerificationCode: false
    };
  },
  watch: {
    // 输入验证码时输入框不能修改
    showClickWordVerificationCode (val) {
      if (val === true) {
        this.codeShow = true
      }
      else if (val === false) {
        this.codeShow = false
      }
    }
  },
  mounted () {
    // 13就是回车键的keycode,这样点击回车就能触发loginBtn这个事件
    this.$keyBoard(this, "loginBtn", 13);
  },
  methods: {
    // 关闭验证码
    handleClickCloseCode () {
      this.showClickWordVerificationCode = false
    },
    // 获取UUID
    getUUid (val) {
      this.uuid = val
    },
    // 验证码弹窗确认按钮点击事件（参数：bool 验证是否通过）
    clickCodeConfirm (result) {
      if (result !== '') {
        let info = {
          userCode: this.loginList.userCode,
          passWord: md5(this.loginList.passWord),
          captchaCode: result,
          uuid: this.uuid
        };

        this.$login.loginIn(info).then(res => {
          localStorage.setItem("userName", res.resultEntity.userName)
          localStorage.setItem("userToken", res.resultEntity.userToken)
          localStorage.setItem("updateTime", res.resultEntity.updateTime)
          localStorage.setItem("userCode", res.resultEntity.userCode)
          this.$message({ type: "success", message: "登陆成功" });
          this.$router.push({ path: "/homeSlide" });
        }).catch(err => {
          this.showClickWordVerificationCode = false
          this.$message.error(err.resultMsg);
        })
      } else {
        this.showClickWordVerificationCode = false
        this.$message.error('验证码错误！');
      }
    },
    //登录按钮
    loginBtn () {
      this.$refs["loginList"].validate(valid => {
        if (valid) {
          this.showClickWordVerificationCode = true
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.yanzhengma {
  position: absolute;
  margin-left: 7px;
  border-radius: 5px;
  height: 40px;
  width: 100px;
}
.login-content {
  height: 100%;
  width: 100%;
  background-image: url('../../assets/login/loginBackGround.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .login-block {
    position: absolute;
    width: 360px;
    height: 375px;
    left: 68.2%;
    top: 27.4%;
    .title {
      height: 56px;
      line-height: 56px;
      font-family: PingFangSC-Semibold;
      font-size: 40px;
      color: #ffffff;
      letter-spacing: 0;
    }
    /deep/.input .el-input__inner {
      background-color: #fff !important;
      color: black !important;
      height: 40px !important;
    }
  }
  .login-form-horizontal {
    margin-top: 30px;
    #login-btn {
      margin-top: 40px;
      width: 360px;
      height: 48px;
      color: white;
      background: #0e71db;
      border: none;
    }
    #login-btn:hover {
      cursor: pointer;
    }
  }
  .linkClass {
    color: #fff;
    position: absolute;
    bottom: 5%;
    left: 48%;
  }
  .linkClass:hover {
    color: #0e71db;
  }
}
.codeBox {
  position: fixed;
  top: calc(50vh - 140px);
  left: calc(50vw - 200px);
}
</style>
